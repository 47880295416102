import {
    login, registerConfirm, registerCodeResend, refreshToken, logout, timeZone, getExpertProfile, GetProjectLength, proposalCreate, searchExpert
    , siteFeedbackMutation, fetchCompanyProfile, FedLogin, assmntSubmit, clientJobsList, loadQuestions, createFeedback, createAlert, newAlertCount, loadAlerts, expertProposals, sendMessage, messageSummary, newMessageCount, loadMessages, loadExpertJobs, clientContractComplete, clientContractTerminate, expertContractsLoad, expertContractUpdate, loadContract, loadProposal, loadExpertOffers, clientContractsLoad, expertSaveJob, createContract, offerStatusUpdate, proposalStatusUpdate, searchJobs, getExpertEducation, getExpertExperience, updateExpertPersonal, proposalLoadAll, updateExpertEducation, loadClientProfile, loadClientBusinessProfile, updateExpertExperience, getExpertProfessional, updateExpertProfessional, getExpertSocial, updateExpertSocial, getExpertCertificate, getExpertTestimonial, updateExpertTestimonial, updateExpertCertificate, ClientPreviewLoad, ExpertPreviewLoad, ExpertRowDelete, ClientJobsUpdate, ClientJobsLoad, ClientJobsDelete, clientJobsRead, clientJobsStatusUpdate, clientDashboardCounts, createOffer, loadClientOffers, loadOffer, createReportIssue, expertDashboardCounts,
    loadJobsInvites, saveJobInvites, paymentHistoryUpdate, userTrxnHistory,  updateSettingNotification, deleteMilestone, createMilestone, updateMilestone, updateContract, accountDelete, deleteMessage, updateMessage
} from './../graphql/mutations';
import { API, graphqlOperation, gqlast } from 'aws-amplify';
import AuthProvider from './../context/AuthProvider';
import gql from 'graphql-tag';
import axios from 'axios';

const user = (localStorage.getItem("connect")) ? JSON.parse(localStorage.getItem("connect")) : null;
 
const apiHeader = user ? {
    "x-api-key": process.env.REACT_APP_AWS_APIKEY,
    "Authorization": user.idToken
} : {
    "x-api-key": process.env.REACT_APP_AWS_APIKEY
};
 

export async function signInCall(email, password) {

    let data = login.replace("$email", email).replace("$pwd", password);
    let response;
    await API.graphql(graphqlOperation(data)).then((result) => {
        response = result;
    }).catch((error) => { response = error; });

    return response;

}

export async function getRefreshToken(email, token, userType) {

    let data = refreshToken.replace("$email", email).replace("$token", token).replace("$userType", userType);
    let response;
    await API.graphql(graphqlOperation(data)).then((result) => {

        response = result;
    }).catch((error) => { response = error; });

    return response;

}
export async function logoutUser() {
    AuthProvider();
    if (localStorage.getItem("connect")) {
        if (user) {
            let data = logout.replace("$email", user.payload["email"]).replace("$token", user.accessToken);
            let response;
            await API.graphql(graphqlOperation(data)).then((result) => {
                response = result;
            }).catch((error) => {
                response = error;
            });
            return response;
        }
    }
}


export async function confirmCode(email, code) {

    let data = registerConfirm.replace("$email", email).replace("$code", code);
    let response;
    await API.graphql(graphqlOperation(data)).then((result) => {
        response = result;
    });

    return response;

}
export async function codeResend(email) {

    let data = registerCodeResend.replace("$email", email);
    let response;
    await API.graphql(graphqlOperation(data)).then((result) => {
        response = result;
    });

    return response;

}
export async function GetTimeZone(code) {

    let data = timeZone.replace("$code", code);
    let response;
    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });

    return response;

}
export async function GetExpertProfile() {
    let response;
    if (user) {

        let data = getExpertProfile.replace("$sub", user.payload["sub"]);

        //await API.graphql({
        //    ...graphqlOperation(data), headers: {
        //        Authorization: `bearer ` + user.accessToken
        //    }
        //}).then((result) => {            
        //    response = result;
        //}); 

        await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
            { query: data }
            , {
                headers: apiHeader
            }
        ).then(result => {  
                response = result.data; 
        });

    }
    return response;
}
export async function GetExpertProfessional() {
    let response;
    if (user) {

        let data = getExpertProfessional.replace("$sub", user.payload["sub"]);

        
        await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
            { query: data }
            , {
                headers: apiHeader
            }
        ).then(result => {
            response = result.data;
        });
    }
    return response;

}
export async function GetExpertEducation(expertId) {



    let response;
    if (user) {

        let data = getExpertEducation.replace("$expertId", expertId);

        await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
            { query: data }
            , {
                headers: apiHeader
            }
        ).then(result => {
            response = result.data;
        });
    }
    return response;

}
export async function GetExpertExperience(expertId) {
    let response;
    if (user) {

        let data = getExpertExperience.replace("$expertId", expertId);;

        //await API.graphql(graphqlOperation(data)).then((result) => {
        //    response = result;
        //});
        await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
            { query: data }
            , {
                headers: apiHeader
            }
        ).then(result => {
            response = result.data;
        });
    }
    return response;

}

export async function UpdateExpertProfile(profileData) {

    let response;

    if (user) {

        const percentage = 10 + 35 + ((profileData.phone != '') ? 1 : 0) + ((profileData.languages != '') ? 1 : 0) + ((profileData.address2?.trim() != '') ? 1 : 0);

        const data = updateExpertPersonal.replace("$sub", user.payload["sub"]).
            replace("$email", user.payload["email"]).
            replace("$userType", user.payload["custom:usertype"]).
            replace("$fName", profileData.fName.replaceAll('"', ``)).
            replace("$lName", profileData.lName.replaceAll('"', ``)).
            replace("$address1", profileData.address1.replaceAll('"', `'`).replaceAll(/\\/g, "")).
            replace("$address2", (profileData.address2)? profileData.address2.replaceAll('"', `'`).replaceAll(/\\/g, ""):"").
            replace("$avatar", profileData.avatarName).
            replace("$browserCountry", profileData.browserCountry).
            replace("$browserTimezone", profileData.browserTimezone).
            replace("$city", profileData.city.replaceAll('"', `'`).replaceAll(/\\/g, "")).
            replace("$state", profileData.state.replaceAll('"', `'`).replaceAll(/\\/g, "")).
            replace("$country", profileData.countryid).
            replace("$phone", profileData.phone).
            replace("$zip", profileData.zip).
            replace("$photo", profileData.photoName).
            replace("$timeZone", profileData.timezoneid).
            replace("$percentage", percentage).
            replace("$otherLangs", profileData.languages.replaceAll('"', `'`).replaceAll(/\\/g, ""));

       
        
        await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
            { query: data }
            , {
                headers: apiHeader
            }
        ).then(result => {
            response = result.data;
        });
    }
    return response;

}

export async function UpdateExpertProfessional(profileData) {

    let response; // indTypeIds : "$indTypeIds"

    if (user) {

        const percentage = 30 + ((profileData.resumeName != '') ? 5 : 0);

        const data = updateExpertProfessional.replace("$sub", user.payload["sub"]).
            replace("$email", user.payload["email"]).
            replace("$userType", user.payload["custom:usertype"]).
            replace("$title", profileData.title.replaceAll('"', `'`).replaceAll(/\\/g, "")).
            replace("$skillIds", profileData.skillSet).
            replace("$weeklyHrsId", profileData.weeklyHrsId).
            replace("$resume", profileData.resumeName).
            replace("$profile", profileData.profileDetails.replaceAll('"', `'`).replaceAll(/\\/g, "")).
            replace("$expLevelId", profileData.expLevelId).
            replace("$dailyRate", profileData.dailyRate).
            replace("$compIds", profileData.compliance).
            replace("$indTypeIds", profileData.industryType).
            replace("$compCatIds", profileData.compCategory).
            replace("$percentage", percentage);


        //await API.graphql(graphqlOperation(data)).then((result) => {
        //    response = result;
        //}).catch((error) => {
        //    response = error;
        //});
        await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
            { query: data }
            , {
                headers: apiHeader
            }
        ).then(result => {
            response = result.data;
        });

    }
    return response;

}

export async function GetClientProfile() {

    let response;
    if (user) {

        let client_data = loadClientProfile.replace("$sub", user.payload["sub"]);

        //await API.graphql(graphqlOperation(client_data)).then((result) => {
        //    response = result;

        //}).catch(data => { response = data; });
        await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
            { query: client_data }
            , {
                headers: apiHeader
            }
        ).then(result => {
            response = result.data;
        });

    }
    return response;

}



export async function UpdateClientProfile(profileData) {

    let response;

    const mutation = `mutation CPWMutation {
        clientPerUpdate(input: {email: "$email", phone: "$phone", sub: "$sub", firstName: "$fName", lastName: "$lName", profilePercentage: $percentage}) {
          responseData {
            code
            errors
            message
            status
          }
        }
      }`;


    if (user) {

        // const percentage = 20+20 ;
        const percentage = 40 + ((profileData.phoneNumber != '') ? 5 : 0);


        const data = mutation.replace("$sub", user.payload["sub"]).
            replace("$email", user.payload["email"]).
            replace("$fName", profileData.fName.replaceAll('"', ``)).
            replace("$lName", profileData.lName.replaceAll('"', ``)).
            replace("$phone", profileData.phoneNumber).
            replace("$percentage", percentage);

        //await API.graphql(graphqlOperation(data)).then((result) => {
        //    response = result;
        //}).catch((error) => {
        //    response = error;
        //});
        await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
            { query: data }
            , {
                headers: apiHeader
            }
        ).then(result => {
            response = result.data;
        });
    }
    return response;
}

export async function GetClientBusinessProfile() {
    let response;
    if (user) {

        let data = loadClientBusinessProfile.replace("$sub", user.payload["sub"]);

        //await API.graphql(graphqlOperation(data)).then((result) => {
        //    response = result;
        //});
        await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
            { query: data }
            , {
                headers: apiHeader
            }
        ).then(result => {
            response = result.data;
        });
    }

    return response;

}

export async function UpdateClientBusinessProfile(profileData) {
    let response;
    const mutation = `mutation CBUMutation {
                    clientBusUpdate(input:{sub: "$sub", email: "$email", address1: "$address1", address2: "$address2", certificate: "$certificate", city: "$city", company: "$company", countryId: $countryId, desc: "$desc", indTypeIds : "$indTypeIds" , logo: "$logo", profilePercentage: $percentage, state: "$state",zip: "$zip", tagline: "$tagline", timezoneId: $timeZone, website: "$website"})
                    {
                    responseData {
                    code
                    errors
                    message
                    status
                  }
                }
              }`;


    if (user) {

        const percentage = 50 + ((profileData.tagline != '') ? 3 : 0) + ((profileData.address2 != '') ? 2 : 0);
        const data = mutation.replace("$sub", user.payload["sub"]).
            replace("$email", user.payload["email"]).
            replace("$address1", profileData.address1.replaceAll('"', `'`).replaceAll(/\\/g, "")).
            replace("$address2", profileData.address2.replaceAll('"', `'`).replaceAll(/\\/g, "")).
            replace("$certificate", profileData.certificatename).
            replace("$city", profileData.city.replaceAll('"', `'`).replaceAll(/\\/g, "")).
            replace("$company", profileData.cname.replaceAll('"', `'`).replaceAll(/\\/g, "")).
            replace("$countryId", profileData.countryid).
            replace("$desc", profileData.cdesc.replaceAll('"', `'`).replaceAll(/\\/g, "")).
            replace("$indTypeIds", profileData.industryType).
            replace("$logo", profileData.clogoname).
            replace("$percentage", percentage).
            replace("$state", profileData.state.replaceAll('"', `'`).replaceAll(/\\/g, "")).
            replace("$zip", profileData.zip).
            replace("$tagline", profileData.tagline.replaceAll('"', `'`).replaceAll(/\\/g, "")).
            replace("$timeZone", profileData.timezoneid).
            replace("$website", decodeURI(profileData.cwebsite));


        //await API.graphql(graphqlOperation(data)).then((result) => {
        //    response = result;
        //}).catch((error) => {
        //    response = error;
        //});
        await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
            { query: data }
            , {
                headers: apiHeader
            }
        ).then(result => {
            response = result.data;
        });
        return response;
    }

}

export async function UpdateExpertEducation(row, count) {

    let response;

    if (user) {
        //const percentage = (eduData.filter(item => item["id"] == null).length == 0) ?5:0;
        const percentage = 3;
        
        const data = updateExpertEducation.replace("$sub", user.payload["sub"]).
            replace("[$ID]", (row.id) ? "id:" + row.id.toString() + ',' : '').
            replace("$email", user.payload["email"]).
            replace("$expertId", row.expertId).
            replace("$degreeId", (row.degree) ? row.degree.value : null).
            replace("$startDate", new Date(row.fromDate.toString().replace("00:00:00", "08:00:00")).toISOString().slice(0, 10)).
            replace("$endDate", new Date(row.toDate.toString().replace("00:00:00", "08:00:00")).toISOString().slice(0, 10)).
            replace("$major", row.major.replaceAll('"', `'`).replaceAll(/\\/g, "")).
            replace("$countryId", (row.country) ? row.country.value : null).
            replace("$profilePercentage", percentage).
            replace("$school", row.school.replaceAll('"', `'`).replaceAll(/\\/g, ""));


        //await API.graphql(graphqlOperation(data)).then((result) => {

        //    response = result;
        //}).catch((error) => {

        //    response = error;
        //});


        //});
        await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
            { query: data }
            , {
                headers: apiHeader
            }
        ).then(result => {
            response = result.data;
        });
    }

    return response;

}

export async function UpdateExpertExperience(row, count) {

    let response;

    if (user) {

        const percentage = 5;

        const data = updateExpertExperience.replace("$sub", user.payload["sub"]).
            replace("[$ID]", (row.id) ? "id:" + row.id.toString() + ',' : '').
            replace("$email", user.payload["email"]).
            replace("$expertId", row.expertId).
            replace("$company", row.company.replaceAll('"', `'`).replaceAll(/\\/g, "")).
            replace("$startDate", new Date(row.fromDate.toString().replace("00:00:00", "08:00:00")).toISOString().slice(0, 10)).
            replace("[$endDate]", (row.toDate) ? "endDate:\"" + new Date(row.toDate.toString().replace("00:00:00", "08:00:00")).toISOString().slice(0, 10) + "\"," : '').
            replace("$current", row.current).
            replace("$expYears", 2).
            replace("$profilePercentage", percentage).
            replace("$role", row.role.replaceAll('"', `'`).replaceAll(/\\/g, ""));

        //await API.graphql(graphqlOperation(data)).then((result) => {
        //    response = result;
        //}).catch((error) => {
        //    response = error;
        //});
        await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
            { query: data }
            , {
                headers: apiHeader
            }
        ).then(result => {
            response = result.data;
        });
    }

    return response;

}
export async function GetExpertSocial(expertId) {
    let response;
    if (user) {
        let data = getExpertSocial.replace("$expertId", expertId);
        //await API.graphql(graphqlOperation(data)).then((result) => {
        //    response = result;
        //});
        await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
            { query: data }
            , {
                headers: apiHeader
            }
        ).then(result => {
            response = result.data;
        });
    }

    return response;

}
export async function GetExpertCertificate(expertId) {
    let response;
    if (user) {
        let data = getExpertCertificate.replace("$expertId", expertId);
        //await API.graphql(graphqlOperation(data)).then((result) => {
        //    response = result;
        //});
        await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
            { query: data }
            , {
                headers: apiHeader
            }
        ).then(result => {
            response = result.data;
        });
    }

    return response;

}
export async function GetExpertTestimonial(expertId) {
    let response;
    if (user) {
        let data = getExpertTestimonial.replace("$expertId", expertId);
        //await API.graphql(graphqlOperation(data)).then((result) => {
        //    response = result;
        //});
        await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
            { query: data }
            , {
                headers: apiHeader
            }
        ).then(result => {
            response = result.data;
        });
    }

    return response;

}
export async function UpdateExpertSocial(row, count) {

    let response;

    if (user) {
        //const percentage = (eduData.filter(item => item["id"] == null).length == 0) ?5:0;
        const percentage = 2;
        //eduData.map(async (row) => {
        //    console.log(row);
        // {email: "$email", expertId: $expertId, linkId: $linkId, profilePercentage: $profilePercentage, sub: "$sub", url: "$url"}) {
        //  id:null,company: '', role: '', experience: '',current:true, fromDate: '', toDate: ''
        const data = updateExpertSocial.replace("$sub", user.payload["sub"]).
            replace("[$ID]", (row.id) ? "id:" + row.id.toString() + ',' : '').
            replace("$email", user.payload["email"]).
            replace("$expertId", row.expertId).
            replace("$linkId", row.provider.value).
            replace("$profilePercentage", percentage).
            replace("$url", row.link);



        //await API.graphql(graphqlOperation(data)).then((result) => {
        //    response = result;
        //}).catch((error) => {
        //    response = error;
        //});

        await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
            { query: data }
            , {
                headers: apiHeader
            }
        ).then(result => {
            response = result.data;
        });
    }

    return response;

}

export async function UpdateExpertCertificate(row) {

    let response;

    if (user) {
        //const percentage = (eduData.filter(item => item["id"] == null).length == 0) ?5:0;
        const percentage = 5;
        //eduData.map(async (row) => {

        const data = updateExpertCertificate.replace("$sub", user.payload["sub"]).
            replace("[$ID]", (row.id) ? "id:" + row.id.toString() + ',' : '').
            replace("$email", user.payload["email"]).
            replace("$expertId", row.expertId).
            replace("$name", row.name.replaceAll('"', `'`).replaceAll(/\\/g, "")).
            replace("$fileName", row.fileName).
            replace("$profilePercentage", percentage).
            replace("$expiryDate", row.expDate ? new Date(row.expDate.toString().replace("00:00:00", "08:00:00")).toISOString().slice(0, 10) : "").
            replace("$url", encodeURI(row.digitalLink));

        
        //await API.graphql(graphqlOperation(data)).then((result) => {

        //    response = result;
        //}).catch((error) => {

        //    response = error;
        //});
        await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
            { query: data }
            , {
                headers: apiHeader
            }
        ).then(result => {
            response = result.data;
        });

    }

    return response;

}
export async function UpdateExpertTestimonial(row) {

    let response;

    if (user) {
        
        const percentage = 2;
      
        const data = updateExpertTestimonial.replace("$sub", user.payload["sub"]).
            replace("[$ID]", (row.id) ? "id:" + row.id.toString() + ',' : '').
            replace("$email", user.payload["email"]).
            replace("$expertId", row.expertId).
            replace("$name", row.name.replaceAll('"', `'`).replaceAll(/\\/g, "")).
            replace("$fileName", row.fileName).
            replace("$profilePercentage", percentage).
            replace("$url", decodeURI(row.digitalLink)); 

        await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
            { query: data }
            , {
                headers: apiHeader
            }
        ).then(result => {
            response = result.data;
        });
        //});

    }

    return response;

}
export async function GetClientPreviewProfile(sub) {
    let response;
    let input = (sub) ? sub : (user) ? user.payload["sub"] : "";
    if (input && input != "")
    {
        let data = ClientPreviewLoad.replace("$sub", input).replace("$profileupdate", true);
        await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
            { query: data }
            , {
                headers: apiHeader
            }
        ).then(result => {
            response = result.data;
        });
    }
    return response;

}
export async function GetExpertPreviewLoad(sub) {
    let response;
    let input = (sub) ? sub : (user) ? user.payload["sub"] : "";
    if (input && input != "") { 
        let data = ExpertPreviewLoad.replace("$sub", input).replace("$profileupdate", true);

    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });
}
    return response;

}

export async function GetClientProfileLoad(sub) {
    let response;
    let input = (sub) ? sub : (user) ? user.payload["sub"] : "";
    if (input && input != "") {
        let data = ClientPreviewLoad.replace("$sub", input).replace("$profileupdate", false);
        await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
            { query: data }
            , {
                headers: apiHeader
            }
        ).then(result => {
            response = result.data;
        });
    }
    return response;

}
export async function GetExpertProfileLoad(sub) {
    let response;
    let input = (sub) ? sub : (user) ? user.payload["sub"] : "";
    if (input && input != "") {
        let data = ExpertPreviewLoad.replace("$sub", input).replace("$profileupdate", false);

        await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
            { query: data }
            , {
                headers: apiHeader
            }
        ).then(result => {
            response = result.data;
        });
    }
    return response;

}
export async function DeleteExpertRow(id, type, islastRow) {
    let response;

    let data = ExpertRowDelete.replace("$id", id).replace("$type", type).replace("$sub", (user) ? user.payload["sub"] : "");

    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });

    return response;
}

export async function UpdateClientJob(jobData) {

    let response;

    if (user) {
        const data = ClientJobsUpdate.replace("$email", user.payload["email"]).
            replace("[$ID]", (jobData.id) ? "id:" + jobData.id.toString() + ',' : '').
            replace("$title", jobData.title.replaceAll('"', `'`).replaceAll(/\\/g, "")).
            replace("$skillIds", jobData.skillSet).
            replace("$weeklyHrsId", jobData.weeklyHrsId).
            replace("$rangeRateTypeId", jobData.rateTypeId == 0 ? null : jobData.rateTypeId).
            replace("$desc", jobData.desc).
            replace("$expLevelId", jobData.expLevelId).
            replace("$projLengthId", jobData.projLengthId).
            replace("$indTypeIds", jobData.industryType).
            replace("$dailyRate", jobData.dailyRate == "" ? null : jobData.dailyRate).
            replace("$hourlyRate", jobData.hourlyRate == "" ? null : jobData.hourlyRate).
            replace("$fixedRate", jobData.fixedRate).
            replace("$countryId", jobData.countryId).
            replace("$compCatIds", jobData.compCategory).
            replace("$rateFrom", jobData.rateRangeFrom).
            replace("$rateTo", jobData.rateRangeTo).
            replace("$type", jobData.jobType).
            replace("$city", jobData.city.replaceAll('"', `'`).replaceAll(/\\/g, "")).
            replace("$state", jobData.state.replaceAll('"', `'`).replaceAll(/\\/g, "")).
            replace("$clientId", jobData.clientId).
            replace("$statusId", (jobData.statusId) ? jobData.statusId : '').
            replace("$compIds", jobData.compliance);


        await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
            { query: data }
            , {
                headers: apiHeader
            }
        ).then(result => {
            response = result.data;
        });
    }
    return response;

}
export async function DeleteClientJob(id, clientId) {
    let response;
    let data = ClientJobsDelete.replace("$id", id).replace("$clientId", clientId);
    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });

    return response;
}

export async function GetClientJobs(clientId) {
    let response;
    if (clientId) {

        let data = ClientJobsLoad.replace("$clientId", clientId);
        await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
            { query: data }
            , {
                headers: apiHeader
            }
        ).then(result => {
            response = result.data;
        });
    }
    return response;
}

export async function ClientJobsRead(guid) {
    let response;
    let data = clientJobsRead.replace("$guid", guid);

    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });
    return response;
}
export async function ClientJobsStatusUpdate(id, clientId, code) {
    let response;
    let data = clientJobsStatusUpdate.replace("$id", id).replace("$clientId", clientId).replace("$jobCode", code).replace("$email", (user) ? user.payload["email"] : "");

    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });

    return response;
}
export async function ClientDashboardCounts(clientId) {
    let response;
    let data = clientDashboardCounts.replace("$clientId", clientId);
    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });
    return response;
}
export async function SearchJobs(keyWords) {
    let response;
    let data = searchJobs.replace("$keyword", keyWords.keyword.replaceAll(/['"]/g, '')).replace("$country", keyWords.country).
        replace("$expLvlIds", (keyWords.expLvlIds) ? keyWords.expLvlIds : '').replace("$jobTypes", (keyWords.jobTypes) ? keyWords.jobTypes : '').
        replace("$projLengthIds", (keyWords.projLengthIds) ? keyWords.projLengthIds : '')
        .replace("$weeklyHrsIds", (keyWords.weeklyHrsIds) ? keyWords.weeklyHrsIds : '')
        .replace("$skillIds", (keyWords.skillIds) ? keyWords.skillIds : '')
        .replace("$complianceCategoryIds", (keyWords.complianceCategoryIds) ? keyWords.complianceCategoryIds : '')
        .replace("$complianceStandardIds", (keyWords.complianceStandardIds) ? keyWords.complianceStandardIds : '')
        .replace("$industryIds", (keyWords.industryIds) ? keyWords.industryIds : '')
        .replace("$dateSince", (keyWords.dateSince) ? keyWords.dateSince : '')
        .replace("$rateRangeFrom", (keyWords.rateRangeFrom) ? keyWords.rateRangeFrom : '')
        .replace("$rateType", (keyWords.rateType) ? keyWords.rateType : '')
        .replace("$rateRangeTo", (keyWords.rateRangeTo) ? keyWords.rateRangeTo : '');

    
    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });
    return response;
}
export async function Createproposal(proposalData) {
    let response;
    if (user) {

        const data = proposalCreate.replace("$attachments", proposalData.resumeName).
            replace("[$ID]", (proposalData.id) ? "id:" + proposalData.id.toString() + ',' : '').
            replace("$bid", proposalData.bidRate).
            replace("$email", proposalData.email).
            replace("$clientId", proposalData.clientId).
            replace("$coverLetter", proposalData.proposalSummary).
            replace("$expertId", proposalData.expertId).
            replace("$jobId", proposalData.jobId);

        await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
            { query: data }
            , {
                headers: apiHeader
            }
        ).then(result => {
            response = result.data;
        });
    }
    return response;

}

export async function ProposalLoadAll(clientId) {
    let response;
    if (user) {
        const data = proposalLoadAll.replace("$clientId", clientId);
        await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
            { query: data }
            , {
                headers: apiHeader
            }
        ).then(result => {
            response = result.data;
        });
    }
    return response;
}

export async function SearchExpert(keyWords) {
    let response;
    let data = searchExpert.replace("$keyword", keyWords.keyword.replaceAll(/['"]/g, '')).replace("$country", keyWords.country)
        .replace("$expLvlIds", (keyWords.expLvlIds) ? keyWords.expLvlIds : '')
        .replace("$weeklyHrsIds", (keyWords.weeklyHrsIds) ? keyWords.weeklyHrsIds : '')
        .replace("$avgRating", (keyWords.avgRating) ? ((keyWords.avgRating == "0") ? '' : keyWords.avgRating) : '')
        .replace("$jobSuccessRate", (keyWords.jobSuccessRate) ? keyWords.jobSuccessRate : "0")
        .replace("$otherLangs", (keyWords.otherLangs) ? keyWords.otherLangs.replaceAll(/['"]/g, '') : '')
        .replace("$skillIds", (keyWords.skillIds) ? keyWords.skillIds : '')
        .replace("$complianceCategoryIds", (keyWords.complianceCategoryIds) ? keyWords.complianceCategoryIds : '')
        .replace("$complianceStandardIds", (keyWords.complianceStandardIds) ? keyWords.complianceStandardIds : '')
        .replace("$industryIds", (keyWords.industryIds) ? keyWords.industryIds : '')
        .replace("$dateSince", (keyWords.dateSince) ? keyWords.dateSince : '')
        .replace("$rateRangeFrom", (keyWords.rateRangeFrom) ? keyWords.rateRangeFrom : '')
        .replace("$rateRangeTo", (keyWords.rateRangeTo) ? keyWords.rateRangeTo : '');

    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });
    return response;
}
export async function ProposalStatusUpdate(id, code) {
    let response;
    let data = proposalStatusUpdate.replace("$id", id).replace("$statusCode", code).replace("$email", (user) ? user.payload["email"] : "");
    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });
    return response;
}
export async function LoadClientOffers(clientId) {
    let response;
    let data = loadClientOffers.replace("$clientId", clientId);
    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });
    return response;
}
export async function LoadExpertOffers(expertId) {
    let response;
    let data = loadExpertOffers.replace("$expertId", expertId);
    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });
    return response;
}

export async function ClientContractsLoad(clientId) {
    let response;
    let data = clientContractsLoad.replace("$clientId", clientId);
    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });
    return response;
}

export async function ClientJobsList(clientId) {
    let response;
    let data = clientJobsList.replace("$clientId", clientId);
    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });
    return response;
}
export async function LoadOffer(offerId) {
    let response;
    let data = loadOffer.replace("$offerId", offerId);
    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });
    return response;
}

export async function LoadProposal(proposalId) {
    let response;
    let data = loadProposal.replace("$proposalId", proposalId);
    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });
    return response;
}


export async function LoadContract(guid) {
    let response;
    let data = loadContract.replace("$guid", guid);

    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });
    return response;
}
//clientId = either clientId or expert id .offerCode 100:New ,200:Accepted,300:rejected,400:Expired,500:withdrawn
export async function OfferStatusUpdate(clientId, offerCode, offerId, expertId, reasonId) {
    let response;
    let data = offerStatusUpdate.replace("$offerId", offerId).replace("$email", user.payload["email"]).replace("$offerCode", offerCode).replace("[ID]", (clientId) ? "clientId:" + clientId : ((expertId) ? "expertId:" + expertId : "")).replace("[reasonID]", (reasonId) ? "rejectReasonId:" + reasonId + "," : "");
  
    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });
    return response;
}

export async function ExpertContractUpdate(contractId, acceptedBy, expertId, termId, termBy, statusCode) {
    let response;
    let data = expertContractUpdate.replace("$contractId", contractId).replace("$expertId", expertId).replace("$statusCode", statusCode).
        replace("[mode]", (statusCode == 200) ? "acceptedBy:\"" + acceptedBy + "\"" : "termBy:\"" + termBy + "\",termId:" + termId);

    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });
    return response;
}
export async function ClientContractTerminate(contractId, clientId, termId, termBy, statusCode) {
    let response;
    let data = clientContractTerminate.replace("$contractId", contractId).replace("$clientId", clientId).replace("$statusCode", statusCode).replace("$termId", termId).replace("$termBy", termBy);

    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });
    return response;
}
export async function ClientContractComplete(contract) {
    let response;
    if (user) {
        let data = clientContractComplete.
            replace("$contractId", contract.contractId).
            replace("$clientId", contract.clientId).
            replace("$expertId", contract.expertId).
            replace("$statusCode", contract.statusCode).
            replace("$jobId", contract.jobId).
            replace("$rating", contract.rating).
            replace("$feedback", contract.feedback).
            replace("$email", user.payload["email"]);

        await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
            { query: data }
            , {
                headers: apiHeader
            }
        ).then(result => {
            response = result.data;
        });
        return response;
    }
}
export async function ExpertContractsLoad(expertId) {
    let response;
    let data = expertContractsLoad.replace("$expertId", expertId);

    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });
    return response;
}

export async function CreateOffer(offerData) {

    let response;

    if (user) {
        const data = createOffer.replace("$email", user.payload["email"]).
            replace("[$ID]", (offerData.id) ? "id:" + offerData.id.toString() + ',' : '').
            replace("$title", offerData.title.replaceAll('"', `'`).replaceAll(/\\/g, "")).
            replace("$weeklyHrsId", offerData.weeklyHrsId).
            replace("$desc", offerData.desc).
            replace("$dailyRate", offerData.dailyRate).
            replace("$hourlyRate", offerData.hourlyRate).
            replace("$fixedRate", offerData.fixedRate).
            replace("$clientId", offerData.clientId).replace("$rateTypeId", offerData.rateTypeId).
            replace("$proposalId", offerData.proposalId).
            replace("$expertId", offerData.expertId).
            replace("$startDate", (offerData.startDate && offerData.startDate != "") ? new Date(offerData.startDate.toString().replace("00:00:00", "08:00:00")).toISOString().slice(0, 10) : "").
            replace("$statusId", null).
            replace("$jobId", offerData.jobId);



        await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
            { query: data }
            , {
                headers: apiHeader
            }
        ).then(result => {
            response = result.data;
        });
    }
    return response;

}
export async function CreateContract(contractData) {

    let response;

    if (user) {
        let data;
        if (contractData.id) {
            data = updateContract.replace("$email", user.payload["email"]).
                replace("[$ID]", (contractData.id) ? "id:" + contractData.id.toString() + ',' : '').
                replace("$title", contractData.title.replaceAll('"', `'`).replaceAll(/\\/g, "")).
                replace("$weeklyHrsId", contractData.weeklyHrsId).
                replace("$desc", contractData.desc).
                replace("$clientId", contractData.clientId).
                replace("$offerId", contractData.offerId).
                replace("$dailyRate", contractData.dailyRate).
                replace("$hourlyRate", contractData.hourlyRate).
                replace("$fixedRate", contractData.fixedRate).
                replace("$rateTypeId", contractData.rateTypeId).
                replace("$hiringPerson", contractData.hiringPerson).
                replace("$proposalId", contractData.proposalId).
                replace("$expertId", contractData.expertId).
                replace("$startDate", (contractData.startDate != "") ? new Date(contractData.startDate.toString().replace("00:00:00", "08:00:00")).toISOString().slice(0, 10) : "").
                replace("$endDate", (contractData.endDate != "") ? new Date(contractData.endDate.toString().replace("00:00:00", "08:00:00")).toISOString().slice(0, 10) : "").
                replace("$jobId", contractData.jobId);

        }
        else {
            data = createContract.replace("$email", user.payload["email"]).
                replace("$title", contractData.title.replaceAll('"', `'`).replaceAll(/\\/g, "")).
                replace("$weeklyHrsId", contractData.weeklyHrsId).
                replace("$desc", contractData.desc).
                replace("$clientId", contractData.clientId).
                replace("$offerId", contractData.offerId).
                replace("$dailyRate", contractData.dailyRate).
                replace("$hourlyRate", contractData.hourlyRate).
                replace("$fixedRate", contractData.fixedRate).
                replace("$rateTypeId", contractData.rateTypeId).
                replace("$hiringPerson", contractData.hiringPerson).
                replace("$proposalId", contractData.proposalId).
                replace("$expertId", contractData.expertId).
                replace("$task", contractData.task).
                replace("$taskDetails", contractData.taskDetail).
                replace("$amount", contractData.amount).
                replace("$dueDate", (contractData.dueDate != "") ? new Date(contractData.dueDate.toString().replace("00:00:00", "08:00:00")).toISOString().slice(0, 10) : "").
                replace("$startDate", (contractData.startDate != "") ? new Date(contractData.startDate.toString().replace("00:00:00", "08:00:00")).toISOString().slice(0, 10) : "").
                replace("$endDate", (contractData.endDate != "") ? new Date(contractData.endDate.toString().replace("00:00:00", "08:00:00")).toISOString().slice(0, 10) : "").
                replace("$jobId", contractData.jobId);
        }


        await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
            { query: data }
            , {
                headers: apiHeader
            }
        ).then(result => {
            response = result.data;
        });
    }
    return response;

}
export async function ExpertSaveJob(expertId, isSave, jobId) {
    let response;
    if (user) {

        const data = expertSaveJob.replace("$expertId", expertId).
            replace("$isSave", isSave).
            replace("$jobId", jobId);

        await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
            { query: data }
            , {
                headers: apiHeader
            }
        ).then(result => {
            response = result.data;
        });
    }
    return response;

}
export async function LoadExpertJobs(expertId, jobMode) {
    let response;
    let data = loadExpertJobs.replace("$expertId", expertId).replace("$jobMode", jobMode);

    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });
    return response;
}
export async function LoadExpertProposals(expertId) {
    let response;
    let data = expertProposals.replace("$expertId", expertId);

    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });
    return response;
}
export async function CreateReportIssue(issueData) {
    let response;
    if (user) {
        let data = createReportIssue.replace("$desc", issueData.description).
            replace("$email", user.payload["email"]).
            replace("$issueCategoryId", issueData.issueCategoryId).
            replace("$title", issueData.issue.replaceAll('"', `'`).replaceAll(/\\/g, "")).
            replace("$userId", issueData.userId).
            replace("$userType", user.payload["custom:usertype"]);


        await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
            { query: data }
            , {
                headers: apiHeader
            }
        ).then(result => {
            response = result.data;
        });
    }
    return response;
}
export async function SendMessage(message) {
    let response;
    // email: "$email", fromTypeId: $fromTypeId, fromUserId: $fromUserId, message: "$message", toTypeId:
    //$toTypeId, toUserId: $toUserId
    let data = sendMessage.replace("$email", message.email).
        replace("$fromTypeId", message.fromTypeId).
        replace("$fromUserId", message.fromUserId).
        replace("$message", message.message.replace(/\r?\n/g, '<br />')).
        replace("$attachment", message.attachment).
        replace("$toTypeId", message.toTypeId).
        replace("$toUserId", message.toUserId);
    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });
    return response;
}
export async function MessageSummary(userId, userTypeId) {
    let response;

    let data = messageSummary.replace("$userId", userId).
        replace("$userTypeId", userTypeId);
    
    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });
    return response;
}
export async function NewMessageCount(userId, userTypeId) {
    let response;

    let data = newMessageCount.replace("$userId", userId).
        replace("$userTypeId", userTypeId);

    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });
    return response;
}

export async function LoadMessages(message) {
    let response; 
    let data = loadMessages.replace("$fromTypeId", message.fromTypeId).
        replace("$fromUserId", message.fromUserId).replace("$toTypeId", message.toTypeId).
        replace("$toUserId", message.toUserId);
    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });
    return response;
}
export async function CreateAlert(alert) {
    let response;
    //input: { alert: "$alert", alertType: "$system", display: $display,status: "$status", toTypeId: $toTypeId, toUserId: $toUserId }) {
    let data = createAlert.replace("[ID]", alert.id ? "id:" + alert.id + "," : "").
        replace("$alert", alert.alert).
        replace("$alertType", alert.alertType).
        replace("$display", alert.display).
        replace("$status", alert.status).
        replace("$toTypeId", alert.toTypeId).
        replace("$toUserId", alert.toUserId);
    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });
    return response;
}

export async function NewAlertCount(userId, userTypeId) {
    let response;

    let data = newAlertCount.replace("$userId", userId).
        replace("$userTypeId", userTypeId);
 
    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });
    return response;
}
export async function LoadAlerts(userId, userTypeId) {
    let response;

    let data = loadAlerts.replace("$userId", userId).
        replace("$userTypeId", userTypeId);

    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });
    return response;
}

export async function ExpertDashboardCounts(expertId) {
    let response;
    let data = expertDashboardCounts.replace("$expertId", expertId);
    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });

    return response;
}

export async function CreateFeedback(feedback) {
    let response;
    //createFeedback(input: { clientId: $clientId, contractId: "$contractId", email: "$email", expertId: $expertId, feedback: "$feedback", jobId: $jobId, rating: "$rating", senderType: $senderType }) {
    if (user) {
        let data = createFeedback.
            replace("$clientId", feedback.clientId).
            replace("$contractId", feedback.contractId).
            replace("$contractId", feedback.contractId).
            replace("$email", user.payload["email"]).
            replace("$expertId", feedback.expertId).
            replace("$feedback", feedback.feedback.replaceAll(/\\/g, "")).
            replace("$rating", feedback.rating).
            replace("$senderType", feedback.senderType).
            replace("$jobId", feedback.jobId);

        await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
            { query: data }
            , {
                headers: apiHeader
            }
        ).then(result => {
            response = result.data;
        });
    }
    return response;
}

export async function LoadQuestionsbyCategory(category) {
    let response;
    let data = loadQuestions.replace("$catCode", category);
    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });

    return response;
}

export async function AssmntSubmit(expertId, answers, ques) {



    let response;
    let data = assmntSubmit.replace("$expertId", expertId).
        replace("$ansq", ques).replace("$ansa", answers);

    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });
    return response;
}

export async function AccountDelete(id, type) {
    let response;
    let data = accountDelete.replace("$id", id).replace("$type", type);
    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });

    return response;
}
export async function AccountFedLogin(fedResponse) {
    let response;
    let data = FedLogin.replace("$fedResp", fedResponse);
    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });

    return response;
}

export async function FetchCompanyProfile(website) {
    let response;
    let data = fetchCompanyProfile.replace("$website", website);
    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });

    return response;
}
export async function SiteFeedbackMutation(rating, feedback, createdBy, location) {



    let response;
    //browser: "$browser", createdBy: "$createdBy", feedback: "$feedback", location: "$location", platform: "$platform", rating: 1
    let data = siteFeedbackMutation.replace("$feedback", feedback.replaceAll('"', `'`).replaceAll(/\\/g, "")).replace("$browser", navigator.appName).replace("$createdBy", (user) ? user.payload["email"] : "Guest").replace("$location", location == "" ? "Denied" : location).replace("$platform", navigator.platform).replace("$rating", rating);

    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });

    return response;
}


export async function DeleteMessage(id) {
    let response;
    let data = deleteMessage.replace("$id", id);
    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });

    return response;
}


export async function UpdateMessage(id, message, attachment) {


    let response;
    let data = updateMessage.replace("$id", id).replace("$message", message.replace(/\r?\n/g, '<br />')).replace("$attachment", attachment);
    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });
    return response;
}

export async function CreateMilestone(value, contractId, userId) {

    // amount: $amount, attachments: "", contractId: $contractId, dueDate: "$dueDate", hoursSpent: "0", status: "100", task: "$task", taskDetails: "$taskDetails" }) {
    let response;
    let data = createMilestone.replace("$userId", userId).replace("$contractId", contractId).replace("$amount", value.amount).replace("$dueDate", (value.dueDate && value.dueDate != "") ? new Date(value.dueDate.toString().replace("00:00:00", "08:00:00")).toISOString().slice(0, 10) : "").replace("$task", value.task.replaceAll(`'`, `''`).replaceAll('"', `'`).replaceAll(/\\/g, "")).
        replace("$taskDetails", value.taskDetail.replaceAll("'", "''").replaceAll(/\\/g, "")).replace("$email", user ? user.payload["email"] : "");

    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });

    return response;
}
export async function UpdateSettingNotification(setting, userType, userId) {
    //emailsenabled,marketingemailsenabled, messagesenabled
    //input: { emailsEnabled: $emailsEnabled, marketingEmailsEnabled: $marketingEmailsEnabled, messagesEnabled: $messagesEnabled, userId: $userId, userType: $userType }) 
    let response;
    let data = updateSettingNotification.replace("$userId", userId).replace("$userType", userType).replace("$emailsEnabled", setting.emailsenabled).replace("$marketingEmailsEnabled", setting.marketingemailsenabled).replace("$messagesEnabled", setting.messagesenabled);

    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });

    return response;
}

export async function UpdateMilestone(value, contractId, userId) {

    //updateMilestone(input: {amount: "$amount", attachments: "", dueDate: "$dueDate", email: "$email", hoursSpent: "$hoursSpent", id: $id, status: $status, task: "$task", taskDetails: "$taskDetails", userType: $userType}) {
    let response;
    let data = updateMilestone.replace("$userId", userId).replace("$id", value.id).replace("$Id", value.id).replace("$amount", value.amount).
        replace("$dueDate", (value.dueDate && value.dueDate != "") ? new Date(value.dueDate.toString().replace("00:00:00", "08:00:00")).toISOString().slice(0, 10) : "").replace("$task", value.task.replaceAll('"', `'`).replaceAll(/\\/g, "")).replace("$taskDetails", value.taskDetail.replaceAll("'", "''").replaceAll(/\\/g, "") + "<br/>" + value.taskEditDetail.replaceAll("'", "''").replaceAll(/\\/g, "")).replace("$email", user ? user.payload["email"] : "").replace("$userType", user ? user.payload["custom:usertype"] : null)
        .replace("$statusCode", value.status.code).replace("$rejectedReason", value.rejectedReason).replace("$hoursSpent", value.hoursSpent ? value.hoursSpent : null).replace("$attachments", value.attachmentName ? value.attachmentName : "").replace("$crNote1", value.crNote1 ? ((value.status.code == "350") ? "" : value.crNote1.replaceAll("'", "''")) : "").replace("$crNote2", value.crNote2 ? ((value.status.code == "350") ? "" : value.crNote2.replaceAll("'", "''")) : "").replace("$crDueDate", (value.crdueDate && value.crdueDate != "") ? new Date(value.crdueDate.toString().replace("00:00:00", "08:00:00")).toISOString().slice(0, 10) : "");

    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });

    return response;
}
export async function DeleteMilestone(id) {


    let response;
    let data = deleteMilestone.replace("$id", id);

    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });

    return response;
}
export async function UserTrxnHistory(id,userType) {


    let response;
    let data = userTrxnHistory.replace("$user_id", id).replace("$user_type_id", userType);
    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });
    return response;
}
export async function PaymentHistoryUpdate(paymentId,status) {

    let response;
    let data = paymentHistoryUpdate.replace("$payment_Id", paymentId).replace("$payment_Status", status);
    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });
    return response;
}
export async function SaveJobInvites(clientId, expertId, jobId) {

    let response;
    let data = saveJobInvites.replace("$clientId", clientId).replace("$expertId", expertId).replace("$jobId", jobId);
   
    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });
    return response;
}
export async function LoadJobsInvites(clientId, expertId) {

    let response;
    let data = loadJobsInvites.replace("$clientId", clientId).replace("$expertId", expertId);

    await axios.post(process.env.REACT_APP_AWS_GRAPHQLENDPOINT,
        { query: data }
        , {
            headers: apiHeader
        }
    ).then(result => {
        response = result.data;
    });
    return response;
}