import React, { useState, useContext, useEffect } from "react"; 
import GlobalContext from "../../context/GlobalContext"; 
import { GetClientProfile, UpdateClientProfile } from "../../utils/apiCalls";
import validator from 'validator';
import ConfirmMessages from "../../utils/confirmMessages";
import { isHtml } from "../../utils/helperFn";
import { GetCountry, GetSocialProviders, GetLanguagesProficiencyLevel, GetLanguages } from "../../utils/GetAPIUtils";
import { ProgressBar } from 'react-bootstrap';
import Message from "../../components/Message";
import Business from "../../sections/ClientDashboard/Business";
 

const Profile = (props) => {
    const gContext = useContext(GlobalContext);
    const [message, setMessage] = React.useState(gContext.visibleProfileMessage);
    const [loaded, setLoaded] = useState(false);
    const [image, setImage] = useState(null);
    const [percentage, setPercentage] = useState([]);
    //const [idverified, setidverified] = useState([]);
    //const data = React.useState({ certificate: '', website: '', certrejectreason: '', websiterejectreason: '', profilecomplete: false, idverified: false, fName: '', lName: '', phoneNumber: '', error: { fName: '', lName: '', phoneNumber: '' }, percentage: 10 });
    const [initialValues, setInitialValues] = React.useState({ certificate: '', website: '', certrejectreason: '', websiterejectreason: '', profilecomplete: false, idverified: false, fName: '', lName: '', phoneNumber: '', error: { fName: '', lName: '', phoneNumber: '' }, percentage: 10 });
    const [values, setvalues] = React.useState({ certificate: '', website: '', certrejectreason: '', websiterejectreason: '', profilecomplete: false, idverified: false, fName: '', phoneNumber: '', lName: '', percentage: 10, error: { fName: '', phoneNumber: '', lName: '' } });
   //const [initialValues, setInitialValues] = React.useState(data);
    // const [values, setvalues] = React.useState(data); 
    const [profileId, SetProfileId] = React.useState(null);
   const [onLoadUserInfo, setOnLoadUserInfo] = useState({ certificate: '', website: '', certrejectreason: '', websiterejectreason: '', profilecomplete: false, idverified: false, fName: '', lName: '', phoneNumber: '', error: { fName: '', lName: '', phoneNumber: '' }, percentage: 10 });
    const [errorMessage, setErrorMessage] = React.useState('');
    const [profileInfo, setProfileData] = React.useState(''); 
    let bgImage;
    useEffect(() => {
        if (errorMessage != "") {
            gContext.setErrorModal(errorMessage);
            gContext.toggleErrorModal();
        }
    }, [errorMessage]);
    useEffect(() => {

        if (message && !gContext.loaderModalVisible) {
            gContext.setSuccessMessage(ConfirmMessages.ProfileConfirmation);
            gContext.toggleSuccessModal();
            setMessage(false);
        }
    }, [message, gContext.loaderModalVisible]);

    function nextPage() {
        gContext.setCurrentPage('6');
        //gContext.setLoaderModalVisible(true);

    }


    /*  Check validity of the fields on submit */
    function checkValidity() {


        let inValid = false;
        let { error, ...rest } = values;

        error.fName = validator.isEmpty(rest.fName.trim()) ? "First Name is required" : ((isHtml(rest.fName) || validator.isURL(rest.fName)) ? "Invalid format" : "");
        error.lName = validator.isEmpty(rest.lName.trim()) ? "Last Name is required" : ((isHtml(rest.lName) || validator.isURL(rest.lName)) ? "Invalid format" : "");
        error.phoneNumber = validator.isEmpty(rest.phoneNumber.trim()) ? "Phone is required" : ((isHtml(rest.phoneNumber) || validator.isURL(rest.phoneNumber)) ? "Invalid format" : error.phoneNumber);
        setvalues({ ...values, error });

        Object.values(error).forEach(val => {
            if (val.length > 0) {
                inValid = true;
            }
        });

        if (inValid)
            return false;
        else
            return true;
    }

    /*  Update Profile */
    async function updateProfile() {
        gContext.setVisibleProfileMessage(false);
        if (checkValidity()) {
            gContext.setLoaderModalVisible(true);
            try {

                const result = await UpdateClientProfile(values);
                if (result.data.clientPerUpdate.responseData.code == "200") {

                    setMessage(true);
                    //window.scrollTo({
                    //    top: 0,
                    //    behavior: 'smooth'

                    //});
                   
                     gContext.getProfileInfo(gContext.userInfo.userType, gContext.userInfo.email, gContext.userInfo.sub);
                    gContext.setCurrentPage("6");
                }
                else {
                    setErrorMessage(JSON.parse(result.data.clientPerUpdate.responseData.message));
                }
            }
            catch (e) {
                console.log(e);
                setErrorMessage("Server error");
                gContext.setLoaderModalVisible(false);
            }
        }

        else {
            setErrorMessage("Required fields are missing.");
            gContext.setLoaderModalVisible(false);
        }

        gContext.setLoaderModalVisible(false);
    }

    /* Fields change event */
    const handleChange = (e) => {
        gContext.setVisibleProfileMessage(false);

        const { name, value } = e.target;

        let error = { ...values.error };
        setErrorMessage("");

        switch (name) {

            case "fName":
                error.fName = validator.isEmpty(value) ? "*" : "";
                break;
            case "lName":
                error.lName = validator.isEmpty(value) ? "*" : "";
                break;

            case "phoneNumber":
                const regex = /^\+?\(?([0-9]{3})\)?\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
                error.phoneNumber = ((value.match(regex)) || value === "") ? "" : "Phone number is not valid";
                break;

            default:
                error[name] = validator.isEmpty(value) ? "*" : "";
                break;
        }




        setvalues({ ...values, error, [name]: value });

    }


    /* Select change event */

    async function BindProfileInfo() {
    

        try {
            setLoaded(false);
           // gContext.setLoaderModalVisible(true);
            const result = await GetClientProfile();
           // console.log("result22", result)
            if (result.data.clientPerLoad.responseData.code == "200") {

                let profile = JSON.parse(result.data.clientPerLoad.responseData.message);

                if (profile && profile.length > 0) {

                    let profileData = { ...values };

                    profileData.fName = profile[0].firstname ? profile[0].firstname:"";
                    profileData.lName = profile[0].lastname ? profile[0].lastname:"";

                    profileData.phoneNumber = (profile[0].phone && profile[0].phone !="null") ? profile[0].phone:"";
                    profileData.percentage = profile[0].profilePercentage;
                    // profileData.idverified = false;
                    profileData.profilecomplete = profile[0].profilecomplete;

                    profileData.idverified = profile[0].idverified;
                    //profileData.profilecomplete = profile[0].profilecomplete;
                    SetProfileId(profile[0].id);
                    setInitialValues(profileData);
                    setvalues(profileData);

                }
            }
                gContext.setLoaderModalVisible(false);
                setLoaded(true);
            //setTimeout(() => {
            //    gContext.setLoaderModalVisible(false);
            //    setLoaded(true);
            //}, 500)
        }
        catch (e) {
            console.log(e);
            gContext.setLoaderModalVisible(false);
          
            setLoaded(true);
        }
    
    }




    const onImageChange = (event) => {
        setImage(event.target.files[0]);
        bgImage = (image) ? { backgroundImage: "url('" + URL.createObjectURL(image) + "')" } : '';

    }

    /*Cancel profile edit*/
    function cancelEdit() {
     
       

       
     
        if(gContext.userInfo.profileInfo != null)
      {
        try{
        gContext.setVisibleProfileMessage(false);
        setvalues(initialValues);
        //BindProfileInfo();
        //gContext.setLoaderModalVisible(false);
        }
        catch(e)
        {
            console.log(e);

        }
    }


    else{ 
        
//setvalues(onLoadUserInfo);
setInitialValues(onLoadUserInfo);
setvalues(initialValues); 

}
 
    }
    

    useEffect(() => {

      
        if(gContext.userInfo.profileInfo == null )
        {
            setLoaded(true);
            setProfileData(JSON.parse(gContext.userInfo.profileInfo));
     
            setvalues(onLoadUserInfo);
            setInitialValues(onLoadUserInfo);
           // BindProfileInfo();
        }
        else{
        gContext.setVisibleProfileMessage(false);
        //setProfileData(JSON.parse(gContext.userInfo.profileInfo));
       setProfileData(JSON.parse(gContext.userInfo.profileInfo));

      //  BindProfileInfo();
    }
       

}, [gContext.userInfo])

    useEffect(() => { 
        
        if (profileId == null) {
            BindProfileInfo(); setLoaded(false);
        }
        document.title = "Profile | Syncuppro";
       
    }, []);

    /* To bind on load */


    const errorHandler = (type) => {
        let error = { ...values.error };
        //error.avatar = (type === "not_image") ? "Upload image file." : type;
        setvalues({ ...values, error });
    }

    return (
        <>
            <span className="screen-darken"></span>

            <div className="page-wrapper">


                <div className="breadcrumb">
                    <div className="container-fluid">

                        <div className="breadcrumb-wrapper">
                            <h4>MY PROFILE</h4>

                            <div className="info-meta">
                                <span className="text-theme m-icon material-icons m-r-10">home</span><span className="text-light-gray">{(gContext.currentPage === "5" || gContext.currentPage === "4") ? "/ Personal" :"/ Business"}</span>
                            </div>

                        </div>

                    </div>
                </div>
                {loaded && <section className="section-sm">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="profile-status-wrapper">

                                <div className="profile-status">
                                    <span>Profile Status :</span>
                                    {values.profilecomplete == true ? <img className="verified-img m-l-15" src="../images/verified_img.svg" alt="" /> :
                                        <img className="verified-img m-l-15" src="../images/notverified_img.svg" alt="" />}
                                    <b className="m-l-10">{values.profilecomplete == true ? " Verified" : " Not Verified"}</b>
                                </div>

                                <div className="profile-progressbar">
                                    <div className="progress">
                                        <div className="progress-bar" style={{ width: ((values.percentage) ? values.percentage : 10).toString() + "%" }}></div>
                                    </div>
                                    <b className="m-l-10">{((values.percentage) ? values.percentage : 10).toString() + '%'}</b>
                                </div>

                            </div>
                        </div>
                    </div>
                </section> }
                
                {!loaded && <section className="section-sm">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="profile-status-wrapper">

                                <div className="profile-status">
                                    <span>Profile Status :</span>
                                    <div className="m-l-10 w-200 movingLoader">&nbsp;</div>
                                </div>

                                <div className="profile-progressbar">
                                    <div className="progress">
                                        <div className="w-200 movingLoader">&nbsp;</div>
                                    </div>
                                    
                                </div>

                            </div>
                        </div>
                    </div>
                </section>}

                {loaded && gContext.userInfo.userID !=null && <>   
                            {(!values.profilecomplete && !values.idverified) &&
                        <section className="section-sm pt-0">
                            <div className="container-fluid">
                                <div className="verification-status-wrap">
                                    <span className="material-icons text-gray f-36 m-r-20">info</span>   <div className="row verification-status-block">


                                    {profileInfo && profileInfo.length > 0 && !profileInfo[0].website && !profileInfo[0].certificate && <div className="col-12">
                                        <div className="verification-list pending text-red">
                                            <span className="material-icons d-none">close</span>
                * Profile completion is pending.
              </div>
                                    </div>  }

                                {(profileInfo && profileInfo.length > 0 && profileInfo[0].website != null) ? (profileInfo[0].websiterejectreason == null || profileInfo[0].websiterejectreason == '') ?
                                    <div className="col-12">
                                        <div className="verification-list pending text-red">
                                            <span className="material-icons d-none">close</span>
                * Company Website verification is pending.
              </div>
                                    </div>   :
                                    <>
                                        <div className="col-12">
                                            <div className="verification-list pending text-red">
                                                <span className="material-icons d-none">close</span>
                * Company Website is not approved - Reason : <span className=" font-italic ">  profileInfo[0].websiterejectreason </span>
              </div>
                                        </div>

                                       </> :
                                    (profileInfo && profileInfo.length > 0 && profileInfo[0].certificate != null) ? (profileInfo[0].certrejectreason == null || profileInfo[0].certrejectreason == '') ?
                                        <div className="col-12">
                                            <div className="verification-list pending text-red">
                                                <span className="material-icons d-none">close</span>
               * Company Registration Certificate verification is pending.
              </div>
                                        </div>   :
                                        <>  <div className="col-12">
                                            <div className="verification-list pending text-red">
                                                <span className="material-icons d-none">close</span>
                * Company Registration certificate is not approved - Reason : <span className=" font-italic ">  profileInfo[0].certrejectreason </span>
                                            </div> </div></> : ''} 

                                   
                                </div> </div>
                        </div>
                    </section>}
                        </>}


                    <section className="section client-profile p-t-0">
                        <div className="container-fluid">

                            <div className="tab-wrapper sticky-top">
                                <ul className="nav nav-tabs" role="tablist">
                                    <li className="nav-item" role="presentation">
                                    <a className={`btn tab-btn   m-r-10 ${(gContext.currentPage === "5" || gContext.currentPage === "4")? 'active':''}`}   onClick={() => { setMessage(""); gContext.setCurrentPage("5");}}>Personal</a>
                                    </li>
                                <li className="nav-item" role="presentation">
                                    <a className={`btn tab-btn  ${(gContext.currentPage === "6" && ((gContext.userInfo.userID && initialValues.fName && initialValues.fName.trim() != "") || (profileId && values.fName && values.fName.trim() != ""))) ? 'active' : ''}`} onClick={() => { if ((gContext.userInfo.userID && initialValues.fName && initialValues.fName.trim() != "") || (profileId && initialValues.fName && initialValues.fName.trim() != "")) { setMessage(""); gContext.setCurrentPage("6"); } }} >Business</a>
                                    </li>
                                </ul>
                            </div>
                        <div className="row d-none">
                            {message && <Message message={ConfirmMessages.ProfileConfirmation} />}
                            {errorMessage !== "" && <> <div className="alert alert-danger">{errorMessage} </div> </>}
                            
                          

                        </div>
          <div className="tab-content">

                            {loaded &&<>

                                {(gContext.currentPage === "5" || gContext.currentPage === "4") &&
                                    <div id="personal" className="tab-pane active show" role="tabpanel">
                                    <div className="card panel  p-0">

                                        <div className="card-header">

                                            <div className="panel-title">
                                                <h6>PERSONAL</h6>
                                            </div>

                                            <div className="panel-action mobile-none">
                                                <button className="btn btn-orange btn-sm bg-green m-r-20 text-white" onClick={() => {
                                                    updateProfile();

                                                }}>Save</button>
                                                <button type="button" className="btn btn-dark-outline btn-sm text-black d-flex align-item-center" onClick={() => {
                                                    cancelEdit();

                                                }}>Cancel</button>
                                            </div>

                                        </div>

                                        <div className="card-body p-b-0">
                                            <div className="mandatory-field">
                                                <span className="text-red">All * fields are mandatory</span>
                                            </div>


                                            <div className="row">

                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                    <div className="forn-group-input">
                                                        <label for="first name" className="form-label m-b-10">First Name<span className="text-red"> * </span></label>
                                                        <input  className="form-control" id="firstname" 
                                                            name="fName" defaultValue={values.fName} value={values.fName}
                                                            disabled={(values.fName || values.fName !="")  && values.idverified}
                                                            type="text"
                                                            className={`form-control   ${(values.error.fName.length > 0) ? 'error' : ''}`}                                                           
                                                            
                                                            placeholder="First Name" onKeyUp={handleChange} onChange={handleChange} disabled={values.idverified === true ? true : false}
                                                        />
                                                        {values.error.fName.length > 1 && (
                                                            <span className="text-red ">{values.error.fName} </span>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                    <div className="forn-group-input">
                                                        <label for="last name" className="form-label m-b-10">Last Name<span className="text-red"> * </span></label>
                                                        <input type="text"  
                                                            name="lName" defaultValue={values.lName} value={values.lName}
                                                            disabled={(values.lName || values.lName != "") && values.idverified}
                                                            type="text"
                                                            className={`form-control    ${(values.error.lName.length > 0) ? ' error' : ''}`}
                                                            id="lName"
                                                            placeholder="Last Name" onChange={handleChange} onKeyUp={handleChange} />
                                                        {values.error.lName.length > 1 && (
                                                            <span className="text-red ">{values.error.lName} </span>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                    <div className="forn-group-input">
                                                            <label for="phonenumber" className="form-label m-b-10">Phone Number<span className="text-red"> * </span> </label>
                                                        <input
                                                            name="phoneNumber" defaultValue={values.phoneNumber} value={values.phoneNumber}
                                                            type="text"
                                                            className={`form-control    ${(values.error.phoneNumber.length > 0) ? 'error' : ''}`}
                                                            id="phoneNumber"
                                                            placeholder="Phone Number" onChange={handleChange} onKeyUp={handleChange}
                                                        />{values.error.phoneNumber.length > 1 && (
                                                            <span className="text-red ">{values.error.phoneNumber} </span>
                                                        )}
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                        <div className="card-footer desktop-hide">
                                            <div className="panel-action">
                                                <button className="btn btn-orange btn-sm bg-green m-r-20 text-white" onClick={() => {
                                                    updateProfile();

                                                }}>Save</button>
                                                <button type="button" className="btn btn-dark-outline btn-sm text-black d-flex align-item-center" onClick={() => {
                                                                        cancelEdit();

                                                                    }}>Cancel</button>
                                            </div>
                                        </div>

                                    </div>
                                    </div>}

                                {(gContext.currentPage === "6") &&
                                    <Business />}</>}

                     
                            {!loaded &&

                                <div className="tab-pane active show" role="tabpanel">
                                    <div className="card panel  p-0">

                                        <div className="card-header">

                                            <div className="client-title movingLoader w-150">
                                                <h6>&nbsp;</h6>
                                            </div>

                                            <div className="panel-action">
                                                <button className="btn btn-orange btn-sm bg-green m-r-20 text-white movingLoader w-50" > &nbsp;</button>
                                                <button type="button" className="btn btn-dark-outline btn-sm text-black d-flex align-item-center movingLoader w-50">&nbsp; </button>

                                            </div>

                                        </div>

                                        <div className="card-body p-b-0">


                                            <div className="row">



                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="forn-group-input">

                                                        <input name="fName"

                                                            className={`form-control   movingLoader }`}

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="forn-group-input">

                                                        <input name="fName"

                                                            className={`form-control   movingLoader }`}

                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <div className="forn-group-input">

                                                        <input name="fName"

                                                            className={`form-control   movingLoader }`}

                                                        />
                                                    </div>
                                                </div>





                                            </div>
                                        </div>

                                        <div className="card-footer">
                                            <div className="panel-action">
                                                <button className="btn btn-orange btn-sm bg-green m-r-20 text-white movingLoader w-50" > &nbsp;</button>
                                                <button type="button" className="btn btn-dark-outline btn-sm text-black d-flex align-item-center movingLoader w-50">&nbsp; </button>
                                            </div>
                                        </div>

                                    </div></div>
                            }                                                 

          </div> 

      </div>       
    </section>



                                                                </div>

           
            <div
                className="dashboard-main-container mt-20 mt-lg-25 min-h-100vh d-none"
                id="dashboard-body"
            >
                <div className="container">
                    <div className="mb-15 mb-lg-23">
                        <div className="row">
                            <div className="font-size-4 font-weight-semibold  bg-athens  w-100  text-green-2  pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5">


                                <div className="row ">
                                    {message && <Message message={ConfirmMessages.ProfileConfirmation} />}
                                    {errorMessage !== "" && <><span className="error py-10   w-100  font-weight-semibold line-height-reset px-8 bg-red-opacity-1 rounded-5 font-size-4">{errorMessage} </span><br /></>}
                                </div>





                            </div>
                           
                            {loaded && <div className="col-xxxl-12  " >

                                {(gContext.currentPage === "5" || gContext.currentPage === "4") &&
                                    <> <h5 className="font-size-8 font-weight-semibold mb-5">

                                    </h5>




                                        <div className="container  rounded-4   py-5   mb-5    ">
                                            <div className="row w-100">
                                                <div className="col-lg-6  d-flex align-items-center  ">
                                                    <span className="font-size-5 font-weight-semibold  text-gray  px-2 mt-1  ">    Profile Status:
                                                        &nbsp; <span className={` text-${(values.profilecomplete == true) ? 'green' : 'red'}-2`}>   {values.profilecomplete == true ? " Verified" : " Not Verified"} </span> </span>


                                                </div>
                                                <div className="col-lg-6    text-right mr-0 ">

                                                    <div className=" container  text-right mr-0" style={{ width: '350px' }}>

                                                        <div className="row ">
                                                            <div className="col-2 text-center text-green-2 font-weight-semibold d-none"></div>
                                                            <div className="col-8 text-center text-green-2 font-weight-semibold  d-none">Profile Completion </div>
                                                            <div className="col-2 text-center text-gray font-weight-semibold  d-none"> </div>
                                                            <div className="col-12 my-1 pb-3">
                                                                <div className="progressBar ">
                                                                    <ProgressBar striped animated label={((values.percentage) ? values.percentage : 10).toString() + ' %'} variant="success" style={{ height: '25px' }} className="bg-black-opacity-2 rounded-4  font-weight-semibold  text-center " now={((values.percentage) ? values.percentage : 10)} />

                                                                </div><div data-aos="fade-in"
                                                                    data-aos-duration="1000" className="  border-1 w-100 rounded-4   text-left bg-black-opacity-2 d-none" style={{ height: '15px' }} > <div className=" h-100   bg-green rounded-4   text-white     font-weight-semibold pl-5 " style={{ width: "25%" }}  >

                                                                    </div>   </div></div>
                                                        </div></div>

                                                </div>


                                            </div></div>
                                        <div className="pb-14">
                                            <div className="width-100  float-right  height-100px background-gray margin-10 pl-30">
                                                <div className="row justify-content-end">
                                                    <div className="col-12 dark-mode-texts ml-2">
                                                        <div className="py-5">


                                                            <button type="button"
                                                                className="btn btn-mini btn-primary bg-green px-3 rounded-5    text-white  text-uppercase " onClick={() => {
                                                                    nextPage();
                                                                }}>NEXT &raquo;
                                                            </button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="contact-form  bg-white rounded-4 ">


                                            <div className="font-size-6 font-weight-semibold mb-11   bg-gray-opacity-2 pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5">
                                                Personal 
                                            </div>

                                            { gContext.userInfo.userID == null?'':<>

                                            
                                            
                                            {gContext.userInfo.userID != null && gContext.userInfo.profileComplete == true && profileInfo && profileInfo.length > 0 && <>
                                                {(values.profilecomplete && values.idverified) &&
                                                    <div className=" d-none pl-sm-10 pl-4 bg-yellow-opacity-1 pt-5 pb-1  my-auto mx-5 rounded  font-weight-semibold text-center" >
                                                        <div className="media ">  <div className="text-green-2 bg-green-opacity-2 circle-56 font-size-6 mr-7">
                                                            <i className="fas fa-info"></i>
                                                        </div><p className=" text-left font-size-4">


                                                                {(profileInfo && profileInfo.length > 0 && (profileInfo[0].website != null) && (profileInfo[0].certificate == null)) ?
                                                                    <span className=" text-green-2 f-16 p-t-5">{ConfirmMessages.ClientWebsite}</span> :
                                                                    <>  <span className=" text-green-2 f-16 p-t-5">{ConfirmMessages.ClientCertificate}</span>
                                                                    </>}


                                                            </p></div>

                                                    </div>
                                                }



                                                {(!values.profilecomplete && !values.idverified) &&
                                                    <div className="pl-sm-10 pl-4 bg-yellow-opacity-1 pt-5 pb-1  my-auto mx-5 rounded  font-weight-semibold text-center" >
                                                        <div className="media ">  <div className="text-green-2 bg-green-opacity-2 circle-56 font-size-6 mr-7">
                                                            <i className="fas fa-info"></i>
                                                        </div>
                                                            <p className="text-left font-size-4">


                                                                {(profileInfo && profileInfo.length > 0 && profileInfo[0].website != null) ? (profileInfo[0].websiterejectreason == null || profileInfo[0].websiterejectreason == '') ?
                                                                    <span className=" text-red f-16 p-t-5">* Company Website verification is pending.</span> :
                                                                    <><span className=" text-red f-16 p-t-5"> * Company Website is not approved - Reason : </span>
                                                                        + <span className=" font-italic text-red f-16 p-t-5">  profileInfo[0].websiterejectreason </span></> :
                                                                    (profileInfo && profileInfo.length > 0 && profileInfo[0].certificate != null) ? (profileInfo[0].certrejectreason == null) ?
                                                                        <span className=" text-red f-16 p-t-5">* Company Registration Certificate verification is pending.</span> :
                                                                        <> <span className=" text-red f-16 p-t-5">* Company Registration certificate is not approved - Reason : </span>
                                                                            + <span className=" font-italic text-red f-16 p-t-5">{profileInfo[0].certrejectreason}</span></> : ''}



                                                            </p></div>

                                                    </div>
                                                }</>}</>} <br />
                                            <div className=" pl-sm-10 pl-4 error font-size-4 pt-2 pb-3">All * fields are mandatory <br /></div>


                                            <form className=" pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-10">
                                                <fieldset>
                                                    <div className="row mb-xl-1 mb-9">
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <label
                                                                    htmlFor="namedash"
                                                                    className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                                >
                                                                    First Name <span className="error ">*</span>
                                                                </label>
                                                                <input
                                                                    name="fName" defaultValue={values.fName} value={values.fName}
                                                                    disabled={values.idverified}
                                                                    type="text"
                                                                    className={`form-control    ${(values.error.fName.length > 0) ? 'form-control-error' : ''}`}
                                                                    id="namedash"
                                                                    placeholder="First Name" onKeyUp={handleChange} onChange={handleChange} disabled={values.idverified === true ? true : false}

                                                                />{values.error.fName.length > 1 && (
                                                                    <span className="text-red ">{values.error.fName} </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <label
                                                                    htmlFor="namedash"
                                                                    className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                                >
                                                                    Last Name <span className="error ">*</span>
                                                                </label>
                                                                <input
                                                                    name="lName" defaultValue={values.lName} value={values.lName}
                                                                    disabled={values.idverified}
                                                                    type="text"
                                                                    className={`form-control    ${(values.error.lName.length > 0) ? 'form-control-error' : ''}`}
                                                                    id="lName"
                                                                    placeholder="Last Name" onChange={handleChange} onKeyUp={handleChange}
                                                                />{values.error.lName.length > 1 && (
                                                                    <span className="text-red ">{values.error.lName} </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <label
                                                                    htmlFor="namedash"
                                                                    className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                                >
                                                                    Phone Number
                                                                </label>
                                                                <input
                                                                    name="phoneNumber" defaultValue={values.phoneNumber} value={values.phoneNumber}
                                                                    type="text"
                                                                    className={`form-control    ${(values.error.phoneNumber.length > 0) ? 'form-control-error' : ''}`}
                                                                    id="phoneNumber"
                                                                    placeholder="Phone Number" onChange={handleChange} onKeyUp={handleChange}
                                                                />{values.error.phoneNumber.length > 1 && (
                                                                    <span className="text-red ">{values.error.phoneNumber} </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12  justify-content-md-end">
                                                        <div className="media py-10 w-100  justify-content-md-end ml-9"  >
                                                            <div className="p-0 m-0 position-relative">
                                                                <button
                                                                    type="button"

                                                                    className="btn btn-primary btn-medium    btn-green  text-white  rounded-5 text-uppercase"
                                                                    style={{ minWidth: '60px', height: '34px' }}
                                                                    onClick={() => {
                                                                        updateProfile();

                                                                    }}>
                                                                    Save
                                                                </button>


                                                            </div>
                                                            <div className="p-0 m-0 position-relative ">
                                                                <button
                                                                    type="button"

                                                                    className="btn btn-primary  btn-medium  btn-green  text-white  ml-5 ml-sm-3 rounded-5 text-uppercase"
                                                                    style={{ minWidth: '80px', height: '34px' }} onClick={() => {
                                                                        cancelEdit();

                                                                    }}>
                                                                    Cancel
                                                                </button>

                                                            </div>
                                                        </div></div>

                                                </fieldset>
                                            </form>
                                        </div>

                                    </>
                                }
                                <div className="pb-14">
                                    <div className="width-100  float-right  height-100px background-gray margin-10 pl-30">
                                        <div className="row justify-content-end">
                                            <div className="col-12 dark-mode-texts ml-2">
                                                <div className="py-5">


                                                    <button type="button"
                                                        className="btn btn-mini btn-primary bg-green px-3 rounded-5    text-white  text-uppercase " onClick={() => {
                                                            nextPage();
                                                        }}>NEXT &raquo;
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </div> }
                            {!loaded &&
                                <div className="col-xxxl-12 pt-15 " > <div className="contact-form  bg-white rounded-4 ">


                                    <div className="font-size-6 font-weight-semibold mb-11 py-8   movingLoader pl-sm-10 pl-4 pr-sm-11 pr-4  ">

                                    </div>


                                    <div className=" pl-sm-10 pl-4 error font-size-4 pt-2 pb-3"> <br /></div>


                                    <form className=" pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-10">
                                        <fieldset>
                                            <div className="row mb-xl-1 mb-9">
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <div
                                                            htmlFor="namedash"
                                                            className=" movingLoader circle-72 mb-4"
                                                        >

                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <div
                                                            htmlFor="namedash"
                                                            className=" movingLoader square-72 mb-4"
                                                        >

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label
                                                            htmlFor="namedash"
                                                            className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                        >

                                                        </label>
                                                        <input


                                                            type="text"
                                                            className={`form-control movingLoader `}

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label
                                                            htmlFor="namedash"
                                                            className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                        >

                                                        </label>
                                                        <input

                                                            type="text"
                                                            className={`form-control movingLoader `}
                                                        />
                                                    </div>
                                                </div> <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label
                                                            htmlFor="namedash"
                                                            className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                        >

                                                        </label>
                                                        <input


                                                            type="text"
                                                            className={`form-control movingLoader `}

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label
                                                            htmlFor="namedash"
                                                            className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                        >

                                                        </label>
                                                        <input

                                                            type="text"
                                                            className={`form-control movingLoader `}
                                                        />
                                                    </div>
                                                </div> <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label
                                                            htmlFor="namedash"
                                                            className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                        >

                                                        </label>
                                                        <input


                                                            type="text"
                                                            className={`form-control movingLoader `}

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label
                                                            htmlFor="namedash"
                                                            className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                        >

                                                        </label>
                                                        <input

                                                            type="text"
                                                            className={`form-control movingLoader `}
                                                        />
                                                    </div>
                                                </div>  
                                            </div>
                                            <div className="col-lg-12  justify-content-md-end">
                                                <div className="media py-10 w-100  justify-content-md-end ml-9"  >
                                                    <div className="p-0 m-0 position-relative">
                                                        <label


                                                            className="form-control movingLoader  movingLoader rounded-5 text-uppercase"
                                                            style={{ minWidth: '60px', height: '34px' }}
                                                        >

                                                        </label>


                                                    </div>
                                                    <div className="px-5 m-0 position-relative ">
                                                        <label


                                                            className="form-control movingLoader  movingLoader rounded-5 text-uppercase"
                                                            style={{ minWidth: '60px', height: '34px' }}
                                                        >

                                                        </label>

                                                    </div>
                                                </div></div>

                                        </fieldset>
                                    </form>
                                </div> </div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Profile;
