import React, { useContext, useState, useEffect } from "react";

import PageWrapper from "../components/PageWrapper";
import { API, graphqlOperation } from 'aws-amplify';
import Dashboard from "../sections/ExpertDashboard/dashboard";
import { getCountry } from './../graphql/queries';
import { GetExpertProfile } from "../utils/apiCalls";
import GlobalContext from "../context/GlobalContext";
import ClientSidebarDashboard from "../sections/ClientDashboard/SidebarDashboard";
import ClientProfile from "../sections/ClientDashboard/Profile";
import ExpertSidebarDashboard from "../sections/ExpertDashboard/SidebarDashboard";
import ExpertProfilePreview from "../sections/ExpertDashboard/ProfileView";
import ClientProfilePreview from "../sections/ClientDashboard/ProfileView";
import Education from "../sections/ExpertDashboard/Education";
import Testimonials from "../sections/ExpertDashboard/Testimonials";
import Certificate from "../sections/ExpertDashboard/Certificate";
import Experience from "../sections/ExpertDashboard/Experience";
import Settings from "../sections/home/Settings";
import ReportIssue from "../sections/Report/Report";
import CompanyDashboard from "../sections/ClientDashboard/CompanyDashboard";
import ExpertProfile from "../sections/ExpertDashboard/Profile";
import ExpertSocial from "../sections/ExpertDashboard/Social";
import ExpertProfessional from "../sections/ExpertDashboard/Professional";
import ClientBusiness from "../sections/ClientDashboard/Business";
import ClientDashboard from "../sections/ClientDashboard/dashboard";
import ClientJobs from "../sections/ClientDashboard/Jobs";
import ClientPostedJobs from "../sections/ClientDashboard/PostedJobs";
import ClientApplicants from "../sections/ClientDashboard/Applicants";
import JobClientView from "../sections/ClientDashboard/JobClientView";
import ExpertDashboard from "../sections/ExpertDashboard/dashboard";
import Instructions from "../sections/Assessment/Instructions";
import Alerts from "../sections/Message/Alerts";
import { useLocation } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css'
import { useNavigate } from "react-router-dom"; 
import { Helmet } from "react-helmet";
//import "../scss/bootstrap.scss";
//import "../scss/main.scss";
export default function Profile() {
    const gContext = useContext(GlobalContext);
    const navigate = useNavigate();
    const [countriesList, setCountriesList] = useState([{}]);
    const pathName = useLocation().pathname.toLowerCase();
    const user = JSON.parse(localStorage.getItem("connect"));
    if (!localStorage.getItem("connect")) {
        navigate("/");
    }


    // [{ "sub": "f180bc26-27fa-4a1b-9b75-fee8dd832db0", "email": "sathya@springinfoservices.com", "firstname": "Sathya", "lastname": "K", "phone": "$pnone", "address1": "address", "address2": "", "city": "LA", "state": "CA", "countryid": null, "avatar": "WhatsApp Image 2022-07-26 at 10.21.38 PM (1).jpeg", "photo": "favicon.png", "idverified": false, "timezoneid": 5, "profileComplete": null }]
    useEffect(() => {
        if (pathName.trim().toLowerCase() == "/notifications") {            
            gContext.setCurrentPage("alerts");
        } 
        if (pathName.trim().toLowerCase() == "/reportissue") {
            gContext.setCurrentPage("18");
        } 
        if (pathName.trim().toLowerCase() == "/companydashboard") {
            gContext.setCurrentPage("19");
        } 
        if (pathName.trim().toLowerCase() == "/settings") {
            gContext.setCurrentPage("8");
        } 
        if (pathName.trim().toLowerCase() == "/profile") {
            getCountries();
            gContext.setCurrentPage("5");
        } 
        if (pathName.trim().toLowerCase() == "/assessment") {
            gContext.setCurrentPage("5");
        } 
        if (pathName.trim().toLowerCase() == "/blog") {
            gContext.setCurrentPage("blog");
        } 
   
        if (pathName.trim().toLowerCase() != "searchjob" && pathName.trim().toLowerCase() != "searchexpert")
            document.body.classList.remove('fix-inner-block');
    }, []); 

    async function getCountries() {
        const result = await API.graphql(graphqlOperation(getCountry));
        if (result.data && result.data.countries) {
            setCountriesList(result.data.countries)
            let countlist = [];
            result.data.countries.map((value) => (
                countlist.push({ value: value.code, label: value.name })));
            setCountriesList(countlist);
        }
    }

    return (
        <>
          
            <PageWrapper
                headerConfig={{
                    bgClass: "bg-default",
                    button: "profile",
                    isFluid: true
                }}
            >
                
                {(  gContext.currentPage === "blog") ? <> <iframe src={process.env.REACT_APP_BLOGURL} /> </> : ''}
                {(gContext.userInfo.userType === "1" && gContext.currentPage === "alerts") ? <> <Alerts /> </> : ''}
                {(gContext.userInfo.userType === "2" && gContext.currentPage === "alerts") ? <> <Alerts /> </> : ''}
                {(gContext.userInfo.userType === "2" && gContext.currentPage === "home") ? <> <Dashboard /> </> : ''}

               
 
                {(gContext.userInfo.userType === "1" && gContext.currentPage === "17") ? <> <ExpertDashboard /> </> : ''}

                {(gContext.currentPage === "8") ? <> <Settings /> </> : ''}
                {(gContext.currentPage === "18") ? <> <ReportIssue /> </> : ''}
                
                
                {(gContext.userInfo.userType === "1" && (gContext.currentPage === "5" || gContext.currentPage === "4" || gContext.currentPage === "6" || gContext.currentPage === "11" || gContext.currentPage === "12" || gContext.currentPage === "13" || gContext.currentPage === "14" || gContext.currentPage === "16" || gContext.currentPage === "assessment")) ? <ExpertProfile country={countriesList} /> : ''}
                
                {(user && typeof (user.payload) != "undefined" && user.payload["custom:usertype"] === "2" && (gContext.currentPage === "5" || gContext.currentPage === "4" || gContext.currentPage === "6")) ? <ClientProfile country={countriesList} /> : ''}
                
                {(gContext.userInfo.userType === "2" && gContext.currentPage === "1") ? <> <ClientDashboard /> </> : ''}
                {(gContext.userInfo.userType === "2" && gContext.currentPage === "2") ? <> <ClientPostedJobs /> </> : ''}
                {(gContext.userInfo.userType === "2" && gContext.currentPage === "3") ? <> <ClientApplicants /> </> : ''}
                {(gContext.userInfo.userType === "2" && gContext.currentPage === "11") ? <> <ClientJobs /> </> : ''}
                {(gContext.userInfo.userType === "2" && gContext.currentPage === "12") ? <> <JobClientView /> </> : ''}
                {(gContext.userInfo.userType === "2" && gContext.currentPage === "19") ? <> <CompanyDashboard /> </> : ''}
                
                {gContext.userInfo.userType === "2" && gContext.currentPage !== "18" && gContext.currentPage !== "8" && pathName.trim().toLowerCase() != "/notifications" && pathName.trim().toLowerCase() != "/profile" && pathName.trim().toLowerCase() != "/dashboard" &&
                    <ClientSidebarDashboard />
                }
               
                {gContext.userInfo.userType === "1" && gContext.currentPage !== "assessment" && gContext.currentPage !== "18" && gContext.currentPage !== "8" && pathName.trim().toLowerCase() != "/notifications" && pathName.trim().toLowerCase() != "/profile" && pathName.trim().toLowerCase() != "/dashboard" &&
                    <> <ExpertSidebarDashboard />
                    </>}
            </PageWrapper>
        </>
    );
};